//-------------------------\\
//--- Classes des views ---\\
//-------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier, on retrouve les différentes fonctions utilisées par les views dans transition.js. Ces fonction
// vont servir à appeler les scripts spécifiques ou les plugins utilisés dans les page web. Les fonctions appelées
// sont réparties dans différents fichiers (slicks, overlay), afin de garder le tout le plus structuré possible.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
import { noSamePageDestination, removeHoverOnMobile, scrollToBlock, clickToScrollToBlock, gestionSideNav, sliderHeight, chambersFilter, hundredVH, dragAndSlide, calendar, proFilters, isScrolledIntoView, stickyBtn } from './functions/functions.js'
import { overlayAlert, overlayMenu, overlaySearch, overlayDropdown, overlayAccordion, overlayShare, overlayCalendar, overlayModal, overlayCookies } from './functions/overlay.js'
import { slickAlert, slickbanner, slickChambers, slickEvents, slickPictures, slickRooms, destroySlick } from './functions/slicks.js'
import { textareaHeight, formContact, formCareer, formInfolettre, fileUpload, select, formSearch, clearInput, inputsAndTextareaLabel, scrollToFormulaire, checkboxEnableButton } from './functions/forms.js'
import { Accordions } from './classes/Accordions.js'
//-----------------------------------------------------------------------------------------------------------------


window.scrollToBlock = scrollToBlock

// Initialisation sur toutes les pages
export class View {
  static init() {
    gestionSideNav()
    removeHoverOnMobile()
    overlayDropdown()
    overlayAlert()
    overlayMenu()
    overlaySearch()
    overlayShare()
    overlayModal()
    overlayCookies()
    overlayAccordion()
    slickAlert()
    formSearch('#form-search-overlay', '#submit-search-overlay')
    clearInput()
    inputsAndTextareaLabel()
    $.fn.BLK_scrollfire()
    window.lazyLoad.lazyBackground()
    $.fn.BLK_fixLazyBrowser()
    clickToScrollToBlock({ selector: '#scrollUp' })
        hundredVH()
    formInfolettre('#formNewsletter', '#submitNewsletter')
  }

  static leave() {
    $(document).off('scroll')
    $(window).off('resize')
    $.fn.BLK_fixLazyBrowser.destroy()
  }
}


// Classe initialisant les scripts présents dans la page d'accueil
export class Home extends View {
  static initHome() {
    this.init()
    slickbanner()
    dragAndSlide()
    //slickEvents()
    slickChambers()
    clickToScrollToBlock({ selector: '#scrolldown', scrollTo: '#scrollDestination' })
    $.fn.BLK_parallax({
      elements: [
        { element: '#slickBanner .image', type: 'background', intensityY: -0.3, },
        { element: '#column1', intensityY: 0.05, offsetY: -175, breakpoint: 1200 },
        { element: '#column2', intensityY: -0.05, offsetY: -175, breakpoint: 1200 }
      ]
    })
  }

  static leaveHome() {
    this.leave()
    destroySlick('#slickBanner')
    destroySlick('#slickChambers')
    //destroySlick('#slickEvents')
    //destroySlick('#slickImage')
    $.fn.BLK_parallax.destroy()
  }
}

// Classe initialisant les scripts présents dans la page de generic
export class Generic extends View {
  static initGeneric() {
    this.init()
    stickyBtn()
    new Accordions()
    let accordions =new Accordions({ containerClass: 'js-accordion-set1' })
    accordions.hash()
    textareaHeight()
    fileUpload()
    select()
  }

  static leaveGeneric() {
    this.leave()
  }
}


// Classe initialisant les scripts présents dans la page des auberges
export class Inns extends View {
  static initInns() {
    this.init()
    stickyBtn()
    chambersFilter()
  }

  static leaveInns() {
    this.leave()
  }
}


// Classe initialisant les scripts présents dans la page de détail d'une chambre
export class Inn extends View {
  static initInn() {
    this.init()
    stickyBtn()
    slickPictures()
    $.fn.BLK_parallax({
      elements: [{ element: '#parallax', intensityY: 0.1 }]
    })
  }

  static leaveInn() {
    this.leave()
    destroySlick('#slickPictures')
    $.fn.BLK_parallax.destroy()
  }
}


// Classe initialisant les scripts présents dans la page à venir
export class Contact extends View {
  static initContact() {
    this.init()
    textareaHeight()
    select()
    formContact()
    scrollToFormulaire()
    checkboxEnableButton()
    clickToScrollToBlock({ selector: '#scrolldown', scrollTo: '#scrollDestination' })
      }

  static leaveContact() {
    this.leave()
  }
}

// Classe initialisant les scripts présents dans la page à venir
export class Jobs extends View {
  static initJobs() {
    this.init()
    formCareer()
    fileUpload()
    clickToScrollToBlock({ selector: '#scrolldown', scrollTo: '#scrollDestination' })
    select()
    checkboxEnableButton()
  }

  static leaveJobs() {
    this.leave()
  }
}


// Classe initialisant les scripts présents dans la page à venir
export class JobsDetails extends View {
  static initJobsDetails() {
    this.init()
    formCareer()
    fileUpload()
    clickToScrollToBlock({ selector: '#scrolldown', scrollTo: '#scrollDestination' })
  }

  static leaveJobsDetails() {
    this.leave()
  }
}

// Classe initialisant les scripts présents dans la page à venir
export class News extends View {
  static initNews() {
    this.init()
    clickToScrollToBlock({ selector: '#scrolldown', scrollTo: '#scrollDestination' })
    select()
  }

  static leaveNews() {
    this.leave()
  }
}


// Classe initialisant les scripts présents dans la page à venir
export class NewsDetails extends View {
  static initNewsDetails() {
    this.init()
    clickToScrollToBlock({ selector: '#scrolldown', scrollTo: '#scrollDestination' })
  }

  static leaveNewsDetails() {
    this.leave()
  }
}


// Classe initialisant les scripts présents dans la page à venir
export class Events extends View {
  static initEvents() {
    this.init()
    clickToScrollToBlock({ selector: '#scrolldown', scrollTo: '#scrollDestination' })
    select()
    overlayCalendar()
    calendar()
  }

  static leaveEvents() {
    this.leave()
  }
}


// Classe initialisant les scripts présents dans la page à venir
export class EventsDetails extends View {
  static initEventsDetails() {
    this.init()
    clickToScrollToBlock({ selector: '#scrolldown', scrollTo: '#scrollDestination' })
  }

  static leaveEventsDetails() {
    this.leave()
  }
}


// Classe initialisant les scripts présents dans la page à venir
export class Packages extends View {
  static initPackages() {
    this.init()
    clickToScrollToBlock({ selector: '#scrolldown', scrollTo: '#scrollDestination' })
    select()
  }

  static leavePackages() {
    this.leave()
  }
}


// Classe initialisant les scripts présents dans la page à venir
export class PackagesDetails extends View {
  static initPackagesDetails() {
    this.init()
    clickToScrollToBlock({ selector: '#scrolldown', scrollTo: '#scrollDestination' })
  }

  static leavePackagesDetails() {
    this.leave()
  }
}


// Classe initialisant les scripts présents dans la page recherche
export class Search extends View {
  static initSearch() {
    this.init()
    select()
    formSearch('#form-search-2', '#submit-search-2')
    clearInput()
    inputsAndTextareaLabel()
    clickToScrollToBlock({ selector: '#scrolldown', scrollTo: '#scrollDestination' })
    proFilters()
  }

  static leaveSearch() {
    this.leave()
  }
}


// Classe initialisant les scripts présents dans la page de maintenance
export class Maintenance extends View {
  static initMaintenance() {
    this.init()
  }

  static leaveMaintenance() {
    this.leave()
  }
}
