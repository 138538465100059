//--------------------------------\\
//--- Validation de formulaire ---\\
//--------------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier, on retrouve toutes les validations de formulaire, les messages d'erreur et le formatage.
// Pour que tout fonctionne, il faut les node-modules formatter et jquery-validation déjà.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
import { OBSERVER } from '../main'
import { formatBytes, isMobile, scrollToBlock } from './functions'
//-----------------------------------------------------------------------------------------------------------------


// LES FONCTIONS
// Ajoute un attribut en considérant si le input est plein ou vide
export const inputsAndTextareaLabel = (root = document) => {
  const elementsString = 'input[type="text"], input[type="email"], textarea',
    formElements = root.querySelectorAll(elementsString),
    formElementsLength = formElements.length
  let i

  for (i = 0; i < formElementsLength; i++)
    formElements[i].parentNode.dataset[formElements[i].tagName.toLowerCase() + 'value'] = formElements[i].value

  const setValue = (e) => e.target.parentNode.dataset[e.target.tagName.toLowerCase() + 'value'] = e.target.value

  OBSERVER.add({
    name: 'inputsAndTextareaLabel',
    event: 'input',
    target: elementsString,
    root: root,
    function: setValue
  })

  OBSERVER.on('inputsAndTextareaLabel')
}


// Permet de supprimer le contenu d'un input
export const clearInput = (root = document) => {
  const onClick = (e) => {
    let input = e.currentTarget.parentNode.querySelector('.js-input-to-clear')
    input.value = ''
    input.focus()
    input.parentElement.dataset.inputvalue = ''
    input.classList.remove('valid')
  }

  OBSERVER.add({
    name: 'clearInput',
    event: 'click',
    target: '.js-clear-input',
    root: root,
    function: onClick
  })

  OBSERVER.on('clearInput')
}


// Modification de la hauteur d'un textarea selon son contenu
export const textareaHeight = (root = document) => {
  const onInput = (e) => {
    e.currentTarget.style.height = '5px'
    e.currentTarget.style.height = `${e.currentTarget.scrollHeight <= 32 ? 32 : e.currentTarget.scrollHeight}px`
  }

  OBSERVER.add({
    name: 'textareaHeight',
    event: 'input',
    target: 'textarea',
    root: root,
    function: onInput
  })

  OBSERVER.on('textareaHeight')
}


// Permet de changer le label des input files
export const fileUpload = (root = document) => {
  const clear = (i, element, currentLabelText) => {
    element.value = ''
    element.nextElementSibling.querySelector('.field__text').innerText = currentLabelText
    element.parentNode.dataset['file'] = ''
    OBSERVER.off(`clear${i}`)
  }

  const changeLabel = (e) => {
    const self = e
    const label = e.currentTarget.nextElementSibling.querySelector('.field__text')
    const currentLabelText = label.innerText
    let i, newLabel = '', fileLength = e.currentTarget.files.length

    if ('files' in e.currentTarget) {
      if (fileLength !== 0) {
        for (i=0; i<fileLength; i++) {
          let file = e.currentTarget.files[i]

          if ('name' in file) newLabel += `${file.name}`

          const onClear = () => clear(i, self.target, currentLabelText)

          OBSERVER.add({
            name: `clear${i}`,
            event: 'click',
            target: e.currentTarget.previousElementSibling,
            root: root,
            function: onClear
          })

          OBSERVER.on(`clear${i}`)
        }
        e.currentTarget.parentNode.dataset['file'] = newLabel
        label.innerText = newLabel
      }
    }
  }

  OBSERVER.add({
    name: 'fileUpload',
    event: 'change',
    target: 'input[type=file]',
    root: root,
    function: changeLabel
  })

  OBSERVER.on('fileUpload')
}


// Création du custom select (doc: https://github.com/pytesNET/tail.select)
export const select = () => {
  if (!isMobile())
    tail.select('select', { animate: false })
}



// LES MESSAGES
// Fonction contenant tous les messages
export function getMessages() {
  if($('html')[0].lang == 'en') {
    return {
      required: 'This field is required.',
      select: 'Please chose an option.',
      email: 'Please enter a valid email address.',
      phone: 'Please enter a valid phone number.',
      postale: 'Please enter a valid ZIP code.',
      date: 'Please enter a valid date.',
      accept: 'The file must be a document pdf, docx, doc or rtf.',
      file: 'Please provide a adequate file.'
    }
  } else {
    return {
      required: 'Ce champ est obligatoire.',
      select: 'Veuillez sélectionner une option.',
      email: 'Veuillez fournir une adresse électronique valide.',
      phone: 'Veuillez fournir un numéro de téléphone valide.',
      postale: 'Veuillez fournir un code postal valide.',
      date: 'Veuillez fournir une date valide.',
      accept: 'Le fichier doit être un document pdf, docx, doc ou rtf.',
      file: 'Veuillez lier le fichier adéquat pour ce champ.'
    }
  }
}



// LES RÈGLES __________
// Règle du email
export function validationEmail() {
  jQuery.validator.addMethod('courriel', function(value, element) {
    return this.optional(element) || /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/.test(value)
  })
}



// LES FORMATS __________
// Format du téléphone
export function formatPhone(element) {
  $(element).mask('000 000-0000')
}

// Format du code postal
export function formatCodePostal(element) {
  $(element).mask('ABA BAB', {'translation': {
    A: {pattern: /[A-Za-z]/},
    B: {pattern: /[0-9]/}
  }})
}

// Format de la date
export function formatDate(element) {
    $(element).mask('00/00/0000')
}



// LES VALIDATIONS __________
// Fonction exécutant la validation de chaque formulaire
export function formValidation(form, submit, rules, messages, handler) {
  $.validator.setDefaults({
    ignore: []
  })
  $(form).validate({
    debug: false,
    rules: rules,
    messages: messages,
    errorElement: 'div',
    errorPlacement: function(error, element) {
      var placement = $(element).data('error')
      if (element.attr('type') == 'radio') {
        error.insertBefore(element.closest('.radiobox-field').find('.errorblock'))
      } else if (element.attr('type') == 'checkbox') {
        error.insertBefore(element.closest('.checkbox-field').find('.errorblock'))
      } else if (placement) {
        $(placement).append(error)
      } else {
        error.insertBefore(element)
      }
    },
    submitHandler: function() {
      if (typeof handler != 'undefined') {
        handler()
        return false
      }
      return true
    }
  })
  $(submit).click(function() {
    $(form).submit()
  })
}


// Validation du formulaire de contact
export function formContact() {
  validationEmail()
  formatPhone('#contactTelephone')
  formatDate('#contactDate')
  var m = getMessages()
  var rules = {
    firstname:    { required: true },
    lastname:     { required: true },
    email:        { required: true, courriel: true },
    phone:        { required: true, minlength: 12  },
    message:      { required: true }
  }
  var messages = {
    firstname:    { required: m.required },
    lastname:     { required: m.required },
    email:        { required: m.required, courriel: m.email  },
    phone:        { required: m.required, minlength: m.phone },
    message:      { required: m.required },
  }

  formValidation('#formContact', '#submitContact', rules, messages)
}

// Validation du formulaire de contact
export function formCareer() {
  validationEmail()
  formatPhone('#cvTelephone')
  var m = getMessages()
  var rules = {
    prenom:    { required: true },
    nom:       { required: true },
    courriel:  { required: true, courriel: true },
    telephone: { required: true, minlength: 12  },
    message:   { required: true }
  }
  var messages = {
    prenom:    { required: m.required },
    nom:       { required: m.required },
    courriel:  { required: m.required, courriel: m.email  },
    telephone: { required: m.required, minlength: m.phone },
    message:   { required: m.required },
  }

  formValidation('#formCareer', '#submitCareer', rules, messages)
}


// Validation du formulaire de l'infolettre
export function formInfolettre(form, submit) {
  validationEmail()
  var m = getMessages()
  var rules    = { courriel: { required: true,       courriel: true    }}
  var messages = { courriel: { required: m.required, courriel: m.email }}

  formValidation(form, submit, rules, messages)
}


// Handle le formulaire de payment
function handleContact() {
  $.request('courrielator::onFormSubmit', {
    loading: $.oc.stripeLoadIndicator,
    data: {
      prenom:       $('#contactPrenom').val(),
      nom:          $('#contactNom').val(),
      courriel:     $('#contactCourriel').val(),
      telephone:    $('#contactTelephone').val(),
      date:         $('#contactDate').val(),
      invites:      $('#contactInvites').val(),
      hebergement:  $('#contactHebergement').val(),
      evenement:    $('input[name="contactHebergement"]:checked').val() == 1 ? "Oui" : "Non",
      salle:        $('#contactSalle').val(),
      message:      $('#contactMessage').val(),
    },
    complete: function (output) {
      var response = output.responseJSON
    }
  })
}

// Validation de la recherche'
export function formSearch(form, submit) {

  var m = getMessages()

  var rules = {
    q:     { required: true },
  }
  var messages = {
    q:     { required: m.required },
  }
  formValidation(form, submit, rules, messages)
}

// Fonction exécutant l'animation du scroll vers le formulaire
export function scrollToFormulaire() {
    if (window.location.hash.substring(1) == "formulaire") {
        scrollToBlock({ scrollTo: '#scrollDestination' })
    }
}

export function checkboxEnableButton(root = document) {
    const checkboxCookie = root.querySelector('#cookie-checkbox');

    if (typeof checkboxCookie !== 'undefined' && checkboxCookie !== null) {
        const button = root.querySelector('#submitContact,#submitCareer');
        const checkboxOnClickToggleButton = () => {
            if (checkboxCookie.checked) {
                button.classList.remove('disabled');
                button.removeAttribute('disabled');
            } else {
                button.classList.add('disabled');
                button.setAttribute('disabled', '');
            }
        };

        OBSERVER.add({
            name: 'checkboxCookie',
            event: 'click',
            target: checkboxCookie,
            function: checkboxOnClickToggleButton,
        });

        OBSERVER.on('checkboxCookie');

        checkboxOnClickToggleButton();
    }
}

