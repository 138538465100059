//-----------------------\\
//--- Carousel Slicks ---\\
//-----------------------\\
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Toutes les fonctions utilisant les carousel de slick se retrouve ici. Ces fonction ont besoin du
// node-module slick carousel pour fonctionner (et le css qui vient avec).
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
//-----------------------------------------------------------------------------------------------------------------
// Création du slick de la bannière
export function slickAlert() {
  $('#slickAlert').slick({
    arrows: false,
    dots: false,
    infinite: true,
    autoplay: true,
    draggable: true,
    pauseOnHover: false,
    fade: false,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  })

  var total = $('#alert .slick-slide:not(.slick-cloned)').length
  var displayedTotal = total < 10 ? '0' + total : total
  if (total == 1) {
    $('.alerts__common-container').hide()
    $('.alerts__container').css('margin','0px 60px 0px 20px')
  }
  $('.alerts__common-container .alerts__common-text.total').html(displayedTotal)

  $('.alerts__container').on('beforeChange', function(event, slick, currentSlide, nextSlide){
    var slidePosition = nextSlide + 2 < 10 ? '0' + (nextSlide + 1) : (nextSlide + 1)
    $('.alerts__common-container .alerts__common-text.current').html(slidePosition)
  })
}

// Création du slick de la bannière
export function slickbanner() {
  $('#slickBanner').slick({
    arrows: false,
    appendDots: $('.slickDots'),
    dots: true,
    infinite: true,
    autoplay: true,
    draggable: true,
    pauseOnHover: false,
    fade: true,
    autoplaySpeed: 4000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  })
}

// Création du slick de la bannière
export function slickChambers() {
  $('#slickChambers').on('init', function(event, slick){
    window.lazyLoad.lazyBackground()
  })

  $('#slickChambers').slick({
    arrows: false,
    dots: false,
    infinite: true,
    autoplay: false,
    draggable: true,
    pauseOnHover: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [{
      breakpoint: 1700,
      settings: {
        slidesToShow: 2
      }
    }, {
      breakpoint: 1200,
      settings: {
        slidesToShow: 1
      }
    }]
  })

  var total = $('#slickChambers .slick-slide:not(.slick-cloned)').length
  var displayedTotal = total < 10 ? '0' + total : total
  $('.chambersBlock .pagination .total').html(displayedTotal)

  $('.chambersBlock .slickPrev').click(function(){
    $('#slickChambers').slick('slickPrev')
  })

  $('.chambersBlock .slickNext').click(function(){
    $('#slickChambers').slick('slickNext')
  })

  $('#slickChambers').on('beforeChange', function(event, slick, currentSlide, nextSlide){
    var slidePosition = nextSlide + 1 < 10 ? '0' + (nextSlide + 1) : (nextSlide + 1)
    $('.chambersBlock .pagination .current').html(slidePosition)
  })
}


// Création du slick des événements
export function slickEvents() {
  $('#slickImage').slick({
    asNavFor: '#slickEvents',
    slidesToShow: 1,
    arrows: false,
    dots: false,
    fade: true,
  })

  $('#slickEvents').slick({
    asNavFor: '#slickImage',
    slidesToShow: 1,
    arrows: false,
    dots: false,
    fade: true,
  })

  $('.eventsBlock .slickPrev').click(function(){
    $('#slickEvents').slick('slickPrev')
  })

  $('.eventsBlock .slickNext').click(function(){
    $('#slickEvents').slick('slickNext')
  })
}


// Création du slick des images d'une chambre
export function slickPictures() {
  $('#slickPictures').slick({
    arrows: false,
    dots: false,
    infinite: true,
    draggable: true,
    fade: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  })

  var total = $('#slickPictures .slick-slide:not(.slick-cloned)').length
  var displayedTotal = total < 10 ? '0' + total : total
  $('.picturesBlockSlick .pagination .total').html(displayedTotal)

  $('.picturesBlockSlick .slickPrev').click(function(){
    $('#slickPictures').slick('slickPrev')
  })

  $('.picturesBlockSlick .slickNext').click(function(){
    $('#slickPictures').slick('slickNext')
  })

  $('#slickPictures').on('beforeChange', function(event, slick, currentSlide, nextSlide){
    var slidePosition = nextSlide + 2 < 10 ? '0' + (nextSlide + 1) : (nextSlide + 1)
    $('.picturesBlockSlick .pagination .current').html(slidePosition)
  })
}


export function slickRooms() {
  $('#slickRooms').slick({
    arrows: true,
    prevArrow: $('.prev'),
    nextArrow: $('.next'),
    dots: false,
    infinite: true,
    draggable: true,
    cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
    fade: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  })
}


// Fonction permettant de détruire un carousel Slick
export function destroySlick(carousel, delai) {
  // Paramètres optionnels
  delai = (typeof delai === 'undefined') ? 500 : delai

  // Un délai pour que le slick ne se détruise pas avant que la transition de page ne soit apparu
  setTimeout(function() {
    $(carousel).slick('unslick')
  }, delai)
}
