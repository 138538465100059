(function($) {

  $.fn.BLK_fixLazyBrowser = function(options) {

    // VARIABLES __________
    var settings = $.extend({
      smoothness: 3 // --> Valeur divisant les sauf du fake scroll pour qu'il soit plus rapproché
    }, options)
    var self = this



    // FONCTIONS __________
    $.fn.BLK_fixLazyBrowser.destroy = function() {
      $('body').off("mousewheel")
      return this
    }



    // EXÉCUTION __________
    // Fix les problème d'éléments fixed à l'écran
    if (navigator.userAgent.match(/MSIE 10/i) || navigator.userAgent.match(/Trident\/7\./) || navigator.userAgent.match(/Edge/)) {
      $('body').off("mousewheel")
      $('body').on("mousewheel", function(e) {
        // Pour fix le focus du scroll dans le menu sur ie et edge
        if ($(e.target).parents('#menu').length == 0) {
          event.preventDefault()
          var wd = event.wheelDelta / settings.smoothness
          var csp = window.pageYOffset
          window.scrollTo(0, csp - wd)
        }
      })
    }

    return this
  }

}(jQuery))
