//----------------------------\\
//---- Popup et sidepanel ----\\
//----------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Ici on retrouve tout ce qui a attrait au popup et au sidepanel (popup de connexion, menu mobile)
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
import { Overlay } from './../classes/overlay.js'
import { Accordions } from './../classes/accordions.js'
import { copyTextToClipboard } from './functions.js'
//-----------------------------------------------------------------------------------------------------------------


// Fonction gérant le menu mobile
export function overlayAlert() {
  const overlayAlert = new Overlay('overlayAlert', '', 'showOverlayAlert', '#alert .alerts__close', false, false)
}

// Fonction gérant le menu mobile
export function overlayMenu() {
  const overlayMenu = new Overlay('overlayMenu', '.btnMenu', 'showOverlayMenu', '#overlay-menu .closeOverlay, #overlay-menu-mobile .closeOverlay, .sideNav .sideNavOverlay, .btnSearch', true, false)
}

// Fonction gérant l'overlay de recherche
export function overlaySearch() {
  const overlayMenu = new Overlay('overlaySearch', '.btnSearch', 'showOverlaySearch', '#overlay-search .closeOverlay, .sideNav .sideNavOverlay', true, false)

  $('.btnSearch').on('click', function() {
    $('#form-search-overlay-input').focus()
  })

}

// Fonction gérant l'overlay de recherche
export function overlayDropdown() {
  $('.js-trigger-overlay-dropdown').on('click', function() {
    document.getElementsByTagName("html")[0].setAttribute("data-section", $(this).attr('data-section'));
  })

  // Retirer le data-section lorsque le menu ferme, la recherche ouvre ou un lien est cliqué
  $('.btnMenu, .btnSearch, .closeOverlay').on('click', function() {
    document.getElementsByTagName("html")[0].setAttribute("data-section", '');
  })
}
// Fonction gérant l'overlay de recherche
export function overlayAccordion(root = document) {
  new Accordions({ containerClass: 'js-accordions-menu', scrollToAccordion: false, root: root })
}

// Fonction gérant l'overlay de l'expérience
export function overlayExperience() {
  const overlayExperience = new Overlay('overlayExperience', '.btnExperience', 'showOverlayExperience', '#overlay-experience .closeOverlay', true, false)

  var inProcess = false
  var target = ''
  var waitingTarget = ''

  // Gestion du background image selon l'hover de lien
  $('#overlay-experience .bgLink').on('mouseover', function() {
    target = $(this).attr('data-image')

    if(inProcess) {
      waitingTarget = $(this).attr('data-image')
    } else {
      inProcess = true
      $('#overlay-experience .image:not(.' + target +')').animate({ opacity: 'hide' }, 600)
      $('#overlay-experience .' + target).animate({ opacity: 'show' }, 600, function() {
        if(waitingTarget != '') {
          $('#overlay-experience .image:not(.' + target +')').animate({ opacity: 'hide' }, 600)
          $('#overlay-experience .' + waitingTarget).animate({ opacity: 'show' }, 600, function() {
            waitingTarget = ''
          })
        }
        inProcess = false
      })
    }
  })
}


// Fonction gérant l'overlay de partage
export function overlayShare() {
  if (typeof __sharethis__ !== 'undefined' && __sharethis__.config) {
    __sharethis__.init(__sharethis__.config)
  }

  const overlayShare = new Overlay('overlay-share', '.js-open-overlay-share', 'show-overlay-share', '.js-close-overlay-share', true, false)

  $('#copyShareBtn').click( function() {
    var link = $(this).attr('data-url')
    copyTextToClipboard(link)
    copyTextToClipboard(window.location.href)
    alert('Un url a été copié sur votre presse-papiers.')
    return false
  })

  $('.rubric__share').on('click', function(e) {
    window.history.pushState(null, null, '#' + e.currentTarget.id)
    //pour le partage par courriel
    $('#emailShareBtn').attr('href', 'mailto:?Subject=Stoneham&body=' + window.location.href)
    //pour le partage par facebook ou twitter
    $('#facebookShareBtn').attr('data-url', window.location.href)
    $('#twitterShareBtn').attr('data-url', window.location.href)
  })

  //clean l'url quand on ferme l'overlay de share
  $('.overlay-share__background, .overlay-share__close').on('click', function() {
    window.history.replaceState(null, null, ' ')
    //remettre le partage de la page (sans #)
    $('#emailShareBtn').attr('href', 'mailto:?Subject=Stoneham&body=' + window.location.href)
    $('#facebookShareBtn').attr('data-url', window.location.href)
    $('#twitterShareBtn').attr('data-url', window.location.href)
  })
}


// Fonction gérant l'overlay de modal
export function overlayModal() {
  const overlayModal = new Overlay('overlay-modal', '.js-open-overlay-modal', 'show-overlay-modal', '.js-close-overlay-modal', true, false)
}


// Fonction gérant l'overlay du fil d'ariane
export function overlayCalendar() {
  const overlayCalendar = new Overlay('overlay-calendar', '.js-open-overlay-calendar', 'show-overlay-calendar', '.js-close-overlay-calendar', false, false)
}

// Fonction gérant l'overlay cookies (overlay custom)
// On n'utilise pas l'Observer et la classe Overlay pour assurer la compatibilité avec d'anciens sites.
export function overlayCookies() {
    // Permet de créer un cookie
    function createCookie(name, value, days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 86400000));
        const expires = `expires=${date.toUTCString()}`;
        document.cookie = `${name}=${value};${expires};path=/`;
    }

    // Permet d'obtenir la valeur d'un cookie
    function getCookie(name) {
        const cookieName = `${name}=`;
        const cookieArray = document.cookie.split(';');

        for (let i = 0; i < cookieArray.length; i += 1) {
        let cookie = cookieArray[i];

        while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1);
        }

        if (cookie.indexOf(cookieName) === 0) {
            return cookie.substring(cookieName.length, cookie.length);
        }
        }
        return null;
    }

    if (getCookie('acceptCookies') !== 'true') {
      const html = document.querySelector('html');
      const overlay = document.querySelector('[data-overlay="cookies"]');
      const closeBtn = document.querySelector('.js-close-overlay-cookies');
      const acceptBtn = document.querySelector('.js-overlay-cookies-button-accept');
      const openDelay = 1000;
      const closeDuration = 800;

      // Fonction pour ouvrir l'overlay
      const open = () => {
        html.classList.add(`show-overlay-${overlay.dataset.overlay}`);
      };

      // Fonction pour fermer l'overlay
      const close = () => {
        html.classList.add(`closing-overlay-${overlay.dataset.overlay}`);

        setTimeout(() => {
          html.classList.remove(`show-overlay-${overlay.dataset.overlay}`, `closing-overlay-${overlay.dataset.overlay}`);
        }, closeDuration);
      };

      // Fonction pour accepter les cookies et créer le cookie 'acceptCookies' avec une expiration d'un an (365 jours)
      const acceptCookies = () => {
        createCookie('acceptCookies', 'true', 365);
      };

      // Ouvre l'overlay lors du chargement de la page avec un délai
      setTimeout(open, openDelay);

      // Event listener pour le bouton de fermeture et d'acceptation
      closeBtn.addEventListener('click', close);
      acceptBtn.addEventListener('click', acceptCookies);
    }
  }
