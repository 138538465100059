(function($) {

  $.fn.BLK_parallax = function(options) {

    // VARIABLES __________
    var settings = $.extend({
      elements: [/*{          ---> Le tableau d'objet contenant les éléments parallax
        element: '#element1', ---> L'élément parallax
        type: 'element',      ---> Détermine si le parallax est appliqué à un 'element' ou à un 'background'
        intensityX: 0.1,      ---> L'intensité en chiffre à virgule positif ou négatif déterminant la direction en X
        intensityY: 0.1,      ---> L'intensité en chiffre à virgule positif ou négatif déterminant la direction en Y
        breakpoint: 1024,     ---> Le breakpoint auquel l'élément ne sera plus parallax
        offsetX: 0,           ---> L'offset en X pour modifier la position de l'élément
        offsetY: 0,           ---> L'offset en Y pour modifier la position de l'élément
        customOffset: 0,      ---> Les éléments en position fixed ont besoin d'un custom offset pour être en mode parallax
      }*/]
    }, options)
    var element, type, intensityX, intensityY, offsetX, offsetY, customOffset, breakpoint
    var elementOffset, centreEcran, centreElement, position = ''
    var windowHeight   = $(window).height()
    var windowWidth    = $(window).outerWidth()
    var documentHeight = $(document).scrollTop()
    var isFirefox = typeof InstallTrigger !== 'undefined'
    var isEdge = !(false || !!document.documentMode) && !!window.StyleMedia

    // Test l'existence des éléments
    for (var index = settings.elements.length - 1; index >= 0; index--) {
      if (!$(settings.elements[index].element).length) {
        settings.elements.splice(index, 1)
      }
    }

    // Détermine les valeurs par défaut de chaque
    for(var i=0; i<settings.elements.length; i++) {
      if (settings.elements[i].type == undefined) settings.elements[i].type = 'element'
      if (settings.elements[i].offsetX == undefined) settings.elements[i].offsetX = 0
      if (settings.elements[i].offsetY == undefined) settings.elements[i].offsetY = 0
      if (settings.elements[i].intensityX == undefined) settings.elements[i].intensityX = 0
      if (settings.elements[i].intensityY == undefined) settings.elements[i].intensityY = 0
      if (settings.elements[i].breakpoint == undefined) settings.elements[i].breakpoint = 1400
      if (settings.elements[i].customOffset == undefined) settings.elements[i].customOffset = 0
    }



    // FONCTIONS __________
    // Détruire le parallax
    $.fn.BLK_parallax.destroy = function() {
      $(document).off('scroll')
      $(window).off('resize')
      settings.elements = []
      return this
    }

    // Retourne les raccourcis de chaque élément parallax
    function getRaccourcis(i) {
      element      = $(settings.elements[i].element)
      breakpoint   = settings.elements[i].breakpoint
      type         = settings.elements[i].type
      intensityX   = settings.elements[i].intensityX
      intensityY   = settings.elements[i].intensityY
      offsetX      = settings.elements[i].offsetX
      offsetY      = settings.elements[i].offsetY
      customOffset = settings.elements[i].customOffset
    }

    // Fonction exécutant l'équation créant le parallax
    function equation(paramElement, paramType, paramIntensityX, paramIntensityY, paramOffsetX, paramOffsetY, customOffset) {
      // L'offset de l'élément sauvegardé en data
      elementOffset = (paramElement.css('position') == 'fixed' ? customOffset : paramElement.data('initY'))
      // Le centre du viewport
      centreEcran = documentHeight + (windowHeight / 2)
      // Le centre de l'élément
      centreElement = elementOffset + (paramElement.height() / 2)
      // La position de l'élément est au centre du viewport
      position = centreEcran - centreElement
      // Le calcul pour le parallax appliqué à un élément en X ou Y ou en background en X ou Y
      if (paramType == 'element') {
        paramElement.css('transform', 'translate(' + ((position*paramIntensityX)+paramOffsetX) + 'px,' + ((position*paramIntensityY)+paramOffsetY) + 'px)' )
      } else {
        if (paramIntensityY != 0) paramElement.css('background-position-y', ((position * paramIntensityY) + paramOffsetY) + 'px')
        if (paramIntensityX != 0) paramElement.css('background-position-x', ((position * paramIntensityX) + paramOffsetX) + 'px')
      }
    }

    // Permet de réinitialiser le parallax
    function removeParallax() {
      if (type == 'element') {
        element.css('transform', '')
      } else {
        element.css({'background-position-x': 'center', 'background-position-y': 'center'})
      }
    }

    // Permet de gérer si l'exécution du parallax va avoir lieu ou pas
    function gestionExecution(element, type, execution) {
      if(type == 'element') {
        execution()
      } else {
        ( !isFirefox  && !isEdge ? execution() : element.css('background-attachment', 'scroll'))
      }
    }



    // EXÉCUTION __________
    // Positionnement des éléments au départ
    for(var i=0; i<settings.elements.length; i++) {
      getRaccourcis(i)

      var execution = function() {
        // Si le viewport est plus grand que le breakpoint
        if (windowWidth > breakpoint) {
          element.data('initY', element.offset().top + customOffset)
          equation(element, type, intensityX, intensityY, offsetX, offsetY, customOffset)
        } else {
          removeParallax()
        }
      }

      // Pour des raisons spécifique on ne veut peut-être pas de parallax dans
      // ces navigateurs tout dépendant du contexte pour éviter des bugs
      gestionExecution(element, type, execution)
    }


    // Selon le redimensionnement de la fenêtre, le parallax arrêtera ou reprendra et l'élément sera positionné en conséquence
    $(window).on('resize', function() {

      // Actualisation de la variable
      windowHeight = $(window).height()
      windowWidth  = $(window).outerWidth()

      for(var i=0; i<settings.elements.length; i++) {
        getRaccourcis(i)

        var execution = function() {
          if (windowWidth > breakpoint) {
            removeParallax()
            element.data('initY', element.offset().top) // Prendre le offset de l'élément après le redimensionnement
            equation(element, type, intensityX, intensityY, offsetX, offsetY, customOffset)
          } else {
            removeParallax()
          }
        }

        gestionExecution(element, type, execution)
      }
    })


    // La position sera modifiée en fonction de l'intensité à chaque fois que l'utilisateur scroll
    $(document).on('scroll DOMMouseScroll', function() {

      // Actualisation de la variable
      documentHeight = $(document).scrollTop()

      for(var i=0; i<settings.elements.length; i++) {
        getRaccourcis(i)

        var execution = function() {
          if (windowWidth > breakpoint && documentHeight != 0) {
            element.data('initY', element.offset().top)
            equation(element, type, intensityX, intensityY, offsetX, offsetY, customOffset)
          }
        }

        gestionExecution(element, type, execution)
      }
    })

    return this
  }

}(jQuery))
