/*
|--------------------------------------------------------------------------
| Helper
|--------------------------------------------------------------------------
|
| Les fonctions réutilisable qui ne changeront jamais d'un site à un autre.
*/


// Retourne la position d'un élément dans le DOM
export const getElementOffset = (options) => {
  options.root === undefined ? options.root = document : options.root
  let box = typeof options.element === 'string' ? options.root.querySelector(options.element).getBoundingClientRect() : options.element.getBoundingClientRect()

  let body = document.body
  let docEl = document.documentElement

  let scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop
  let scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft

  let clientTop = docEl.clientTop || body.clientTop || 0
  let clientLeft = docEl.clientLeft || body.clientLeft || 0

  let top  = box.top +  scrollTop - clientTop
  let left = box.left + scrollLeft - clientLeft

  return { top: Math.round(top), left: Math.round(left) }
}
