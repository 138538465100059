//-----------------\\
//--- Les views ---\\
//-----------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Ce fichier contient une seule fonction qui celle-ci contient toutes les views du site. Une views équivaux à
// une page web (homeView = la page d'accueil). On appelle seulement les classes dans global dans les vues, on
// n'appelle pas d'autres fonctions ou déclarer quoi que ce soit dans les vues afin de garder le tout clair.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import Barba from 'barba.js'
import $ from 'jquery'

import {
  View, Home, Generic, Inns, Inn, Contact, News, Jobs, JobsDetails, NewsDetails, Events, EventsDetails, Packages, PackagesDetails, Section, Search, Maintenance
} from './global.js'
//-----------------------------------------------------------------------------------------------------------------

window.Barba = Barba

export function setView() {

  // Page standart __________
  Barba.BaseView.extend({
    namespace: 'sdtView',
    onEnter:          function() {              },
    onEnterCompleted: function() { View.init()  },
    onLeave:          function() { View.leave() },
    onLeaveCompleted: function() {              }
  }).init()


  // Page d'accueil __________
  Barba.BaseView.extend({
    namespace: 'homeView',
    onEnter:          function() {},
    onEnterCompleted: function() { Home.initHome()  },
    onLeave:          function() { Home.leaveHome() },
    onLeaveCompleted: function() {}
  }).init()

  // Page de generic __________
  Barba.BaseView.extend({
    namespace: 'genericView',
    onEnter:          function() {},
    onEnterCompleted: function() { Generic.initGeneric()  },
    onLeave:          function() { Generic.leaveGeneric() },
    onLeaveCompleted: function() {}
  }).init()


  // Page des auberges __________
  Barba.BaseView.extend({
    namespace: 'innsView',
    onEnter:          function() {},
    onEnterCompleted: function() { Inns.initInns()  },
    onLeave:          function() { Inns.leaveInns() },
    onLeaveCompleted: function() {}
  }).init()

  // Page de détail d'une auberge __________
  Barba.BaseView.extend({
    namespace: 'innView',
    onEnter:          function() {},
    onEnterCompleted: function() { Inn.initInn()  },
    onLeave:          function() { Inn.leaveInn() },
    onLeaveCompleted: function() {}
  }).init()

  // Page de contact __________
  Barba.BaseView.extend({
    namespace: 'contactView',
    onEnter:          function() {},
    onEnterCompleted: function() { Contact.initContact()  },
    onLeave:          function() { Contact.leaveContact() },
    onLeaveCompleted: function() {}
  }).init()

  // Page de recherche __________
  Barba.BaseView.extend({
    namespace: 'searchView',
    onEnter:          function() {},
    onEnterCompleted: function() { Search.initSearch()  },
    onLeave:          function() { Search.leaveSearch() },
    onLeaveCompleted: function() {}
  }).init()

  // Page d'emplois __________
  Barba.BaseView.extend({
    namespace: 'jobsView',
    onEnter:          function() {},
    onEnterCompleted: function() { Jobs.initJobs()  },
    onLeave:          function() { Jobs.leaveJobs() },
    onLeaveCompleted: function() {}
  }).init()

  // Page d'actualités __________
  Barba.BaseView.extend({
    namespace: 'jobsDetailsView',
    onEnter:          function() {},
    onEnterCompleted: function() { JobsDetails.initJobsDetails()  },
    onLeave:          function() { JobsDetails.leaveJobsDetails() },
    onLeaveCompleted: function() {}
  }).init()

  // Page d'actualités __________
  Barba.BaseView.extend({
    namespace: 'newsView',
    onEnter:          function() {},
    onEnterCompleted: function() { News.initNews()  },
    onLeave:          function() { News.leaveNews() },
    onLeaveCompleted: function() {}
  }).init()

  // Page d'actualités __________
  Barba.BaseView.extend({
    namespace: 'newsDetailsView',
    onEnter:          function() {},
    onEnterCompleted: function() { NewsDetails.initNewsDetails()  },
    onLeave:          function() { NewsDetails.leaveNewsDetails() },
    onLeaveCompleted: function() {}
  }).init()

  // Page d'évènements __________
  Barba.BaseView.extend({
    namespace: 'eventsView',
    onEnter:          function() {},
    onEnterCompleted: function() { Events.initEvents()  },
    onLeave:          function() { Events.leaveEvents() },
    onLeaveCompleted: function() {}
  }).init()

  // Page d'évènements détails __________
  Barba.BaseView.extend({
    namespace: 'eventsDetailsView',
    onEnter:          function() {},
    onEnterCompleted: function() { EventsDetails.initEventsDetails()  },
    onLeave:          function() { EventsDetails.leaveEventsDetails() },
    onLeaveCompleted: function() {}
  }).init()

  // Page des forfaits __________
  Barba.BaseView.extend({
    namespace: 'packagesView',
    onEnter:          function() {},
    onEnterCompleted: function() { Packages.initPackages()  },
    onLeave:          function() { Packages.leavePackages() },
    onLeaveCompleted: function() {}
  }).init()

  // Page des forfaits __________
  Barba.BaseView.extend({
    namespace: 'packagesDetailsView',
    onEnter:          function() {},
    onEnterCompleted: function() { PackagesDetails.initPackagesDetails()  },
    onLeave:          function() { PackagesDetails.leavePackagesDetails() },
    onLeaveCompleted: function() {}
  }).init()

  // Page de section __________
  Barba.BaseView.extend({
    namespace: 'sectionView',
    onEnter:          function() {},
    onEnterCompleted: function() { Section.initSection()  },
    onLeave:          function() { Section.leaveSection() },
    onLeaveCompleted: function() {}
  }).init()

  // Page de maintenance __________
  Barba.BaseView.extend({
    namespace: 'maintenanceView',
    onEnter:          function() {},
    onEnterCompleted: function() { Maintenance.initMaintenance()  },
    onLeave:          function() { Maintenance.leaveMaintenance() },
    onLeaveCompleted: function() {}
  }).init()
}
