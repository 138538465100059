//-------------------------------\\
//--- Importation des scripts ---\\
//-------------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main.js importe tous les fichiers qu'on a de besoin, ça commence par les node-modules, puis les fichiers
// propres au projet.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////


// Importation des nodes modules __________
import $ from 'jquery'
window.jQuery = $
import 'jquery.easing'
import 'jquery-validation'
import 'slick-carousel'
import '@fancyapps/fancybox/dist/jquery.fancybox.js'
import 'jquery-mask-plugin'
import 'chosen-js'
import 'bootstrap-datepicker'
import '../styles/style.scss';


// Importation des scripts __________
import { LazyLoad } from './classes/lazyLoad.js'
import './plugins/fixLazyBrowser.js'
import './plugins/scrollfire.js'
import './plugins/parallax.js'
import './transition.js'
import './global.js'


// Importation pour les scripts un peu plus bas __________
import Observer from './../libraries/observer.min.js'
import { Loader } from './classes/loader.js'
import { initPageTransition } from './transition.js'

window.lazyLoad = new LazyLoad()
export const OBSERVER = Observer.getInstance()


// Démarrage des scripts __________
$(document).ready(function() {
  initPageTransition()
  setTimeout(function() {
    $('html').removeClass('chargement')
    $.fn.BLK_scrollfire.init()
  }, 600)
  $(document).scrollTop(0)

  // Backend button
  $('body').keydown( function(e) {
    var keycode = (e.keyCode ? e.keyCode : e.which)
    if ((e.ctrlKey  && keycode == 13) || (e.metaKey  && keycode == 13)) {
      location = '/administration'
      return false
    }
  })
})
