//---------------------------\\
//--- Fonctions générales ---\\
//---------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier on retrouve les fonctions générales qu'on ne peut pas vraiment catégoriser
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
import { OBSERVER } from './../main.js'
import anime from 'animejs/lib/anime.es.js'
import { getElementOffset } from './helper.js'
import Barba from 'barba.js'
//-----------------------------------------------------------------------------------------------------------------

// Retire tous les effets d'over sur le mobile
export function removeHoverOnMobile() {
  if (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    try { // prevent exception on browsers not supporting DOM styleSheets properly
      for (var si in document.styleSheets) {
        var styleSheet = document.styleSheets[si]
        if (!styleSheet.rules) continue

        for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
          if (!styleSheet.rules[ri].selectorText) continue
          if (styleSheet.rules[ri].selectorText.match(':hover')) styleSheet.deleteRule(ri)
        }
      }
    } catch (ex) {}
  }
}


// Fonction initialisant les tiroirs
export function tiroirs() {
  $('.tiroirs > li .toggleTiroir').on('click', function() {
    var ouvert = $(this).parent().hasClass('open')
    $('.tiroirs li.open .tiroir').animate({ height: 'hide' }, { duration: 400, specialEasing: { height: 'easeOutExpo' }})
    $('.tiroirs li.open').removeClass('open')
    if (!ouvert) {
      var chosen = this
      setTimeout(function() { $(chosen).parent().addClass('open') }, 0)
      $('.tiroir', $(this).parent()).stop(true, false).animate({ height: 'show' }, { duration: 400, specialEasing: { height: 'easeOutExpo' }})
    }
    return false
  })
}


// Fonction permettant de défiller d'un block à un autre à l'aide d'un clique
// export function scrollToBlock (depart, destination, duration, ajustement) {
//   // Paramètres optionnels
//   duration   = (typeof duration   === 'undefined') ? 600 : duration
//   ajustement = (typeof ajustement === 'undefined') ? 0   : ajustement

//   console.log(depart)
//   // Défilement au clique
//   $(depart).on( 'click', function() {
//     if($(window).width() <= 1024) ajustement = 0
//     $('html, body').animate({
//       scrollTop: ( destination != '' ? $(destination).offset().top + ajustement : 0 )
//     }, duration, 'easeInOutExpo')
//   })
// }

// Fonction ajoutant l'événement 'click' qui appellera la fonction 'scrollToBlock'
export const clickToScrollToBlock = (options) => {
  options.duration === undefined ? options.duration = 800              : options.duration
  options.root     === undefined ? options.root     = document         : options.root
  options.scrollTo === undefined ? options.scrollTo = 'html, body'     : options.scrollTo
  options.easing   === undefined ? options.easing   = 'easeInOutQuart' : options.easing
  options.offset   === undefined ? options.offset   = 0 : options.offset

  const onClick = () => scrollToBlock({
    scrollTo: options.scrollTo,
    duration: options.duration,
    easing: options.easing,
    offset: options.offset,
    root: options.root
  })

  OBSERVER.add({
    name: 'scrollToBlock',
    event: 'click',
    target: options.selector,
    root: options.root,
    function: onClick
  })

  OBSERVER.on('scrollToBlock')
}

// Fonction exécutant l'animation du scroll vers son bloc
export const scrollToBlock = (options = {}) => {
  options.duration === undefined ? options.duration = 800              : options.duration
  options.root     === undefined ? options.root     = document         : options.root
  options.scrollTo === undefined ? options.scrollTo = 'html, body'     : options.scrollTo
  options.easing   === undefined ? options.easing   = 'easeInOutQuart' : options.easing
  options.offset   === undefined ? options.offset   = 0 : options.offset

  const scrollbar = window.document.scrollingElement || window.document.body || window.document.documentElement
  const element = typeof unknownElement === 'string' ? options.root.querySelector(options.scrollTo) : options.scrollTo

  const animation = anime.timeline({
    targets: scrollbar,
    duration: options.duration,
    easing: options.easing
  }).add({ scrollTop: getElementOffset({ element: element, root: options.root }).top + options.offset })

  return animation.finished
}


// Fonction gèrant le side Menu
export function gestionSideNav() {
  var element = document.getElementById('scrollDestination')
  var displayScroll = function() {
    $(window).scrollTop() > 100 ? $('#scrollUp').addClass('active') : $('#scrollUp').removeClass('active')
    if(typeof(element) != 'undefined' && element != null)
      $(window).scrollTop() < 100 ? $('#scrollDown').addClass('active') : $('#scrollDown').removeClass('active')
  }
  displayScroll()

  $(document).on('scroll', function() {
    displayScroll()
  })
}


// Fonction permettant de donner la même hauteur que la plus grande slick slide d'un carousel
export function sliderHeight(){
  var setHeight = () => {
    var slickSlider = $('#slickRooms')
    slickSlider.find('.slick-slide').height('auto')

    var slickTrack = slickSlider.find('.slick-track')
    var slickTrackHeight = $(slickTrack).height()

    slickSlider.find('.slick-slide').css('height', slickTrackHeight + 'px')
  }

  setHeight()
  $(window).on('resize', () => { setHeight() })
}


// Détecte si on est en mobile ou pas
export function isMobile() {
  var isMobile = false

  if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
  || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) {
    isMobile = true
  }

  return isMobile
}


// Retourne le nombre d'octets formaté
export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 ko'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Octets', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}


// Gestion du filtre des chambres
export function chambersFilter() {

  // Déclaration des variables
  const select   = document.getElementById('occupation')
  const wrapper  = document.getElementById('ListWrapper')
  const button   = document.querySelectorAll('.filterBtn')
  const chambers = document.querySelectorAll('.chamber')
  let active = 'toute'
  let tDisplayedChambers = []
  let tChambers = []
  let tOccupation = []

  // remplissage des données des chambres et des occupations dans un array
  chambers.forEach((e) => {
    tChambers.push(e.outerHTML)
    tOccupation.push(e.dataset.occupation)
  })

  // Filtrage des chambre avec le champ select
  select.addEventListener('change', (e) => {
    filtrate( e.target, e.target.options[e.target.selectedIndex].value )
    display()
  })

  // Filtrage des chambre avec les boutons
  button.forEach(e => e.addEventListener('click', (e) => {
    filtrate( e.target, e.target.dataset.filter )
    display()
  }))

  // Permet de filtrer les chambre dans un nouveau array
  const filtrate = (targetedButton, filter) => {
    activeButton(targetedButton)
    active = filter
    tDisplayedChambers = []
    if(active == 'toutes') {
      chambers.forEach((e, index) => {
        tDisplayedChambers.push(tChambers[index])
      })
    } else {
      chambers.forEach((e, index) => {
        if(e.dataset.occupation == active) tDisplayedChambers.push(tChambers[index])
      })
    }
  }

  // Permet de remplacer les chambres affiché par ceux du array
  const display = () => {
    $('#ListWrapper').animate({ opacity: 'hide' }, 400, () => {
      wrapper.innerHTML = ''
      tDisplayedChambers.forEach((e, index) => {
        wrapper.innerHTML += tDisplayedChambers[index]
      })
      $('#ListWrapper').animate({ opacity: 'show' }, 400)
      window.lazyLoad.lazyBackground()
    })
  }

  // Permet de mettre le bouton actif et désactiver les autres au niveau du style
  const activeButton = targetedButton => {
    button.forEach(e => { e.classList.remove('active') })
    targetedButton.classList.add('active')
  }
}


// Permet de donner la hauteur exact en mobile de 100vh
export function hundredVH() {
  OBSERVER.add({ name:'hundredVH', event:'resize', function:setHeight })
  OBSERVER.on('hundredVH')
  setHeight()
  function setHeight() {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }
}



// dragAndSlide Permet de slider les catégories lorsqu'ils sont à l'horizontale
export function dragAndSlide(root = document) {
  const slider = root.querySelector('.js-drag-and-slide')
  let i, startX, timeout, scrollLeft, isDown = false, preventDefault = false

  let links = root.querySelectorAll('.js-drag-and-slide-link')
  let linksLength = links.length

  for (i = 0; i < linksLength; i++)
    links[i].ondragstart = function() { return false }

  function mouseDown(e) {
    isDown = true
    slider.classList.add('js-drag-and-slide-active')
    startX = e.pageX - slider.offsetLeft
    scrollLeft = slider.scrollLeft
    timeout = setTimeout(() => {
      preventDefault = true
    }, 300)
  }

  function mouseleave() {
    isDown = false
    slider.classList.remove('js-drag-and-slide-active')
  }

  function mouseup() {
    clearTimeout(timeout)
    isDown = false
    slider.classList.remove('js-drag-and-slide-active')
  }

  function mousemove(e) {
    if(!isDown) return
    e.preventDefault()
    const x = e.pageX - slider.offsetLeft
    const walk = (x - startX) * 2
    slider.scrollLeft = scrollLeft - walk
  }

  function click(e) {
    if(preventDefault) {
      e.preventDefault()
      e.stopPropagation()
    }
    preventDefault = false
  }

  OBSERVER.add({ name:'dragAndSlide', event:'mousedown',  function: mouseDown,  target: '.js-drag-and-slide'      })
  OBSERVER.add({ name:'dragAndSlide', event:'mouseleave', function: mouseleave, target: '.js-drag-and-slide'      })
  OBSERVER.add({ name:'dragAndSlide', event:'mouseup',    function: mouseup,    target: '.js-drag-and-slide'      })
  OBSERVER.add({ name:'dragAndSlide', event:'mousemove',  function: mousemove,  target: '.js-drag-and-slide'      })
  OBSERVER.add({ name:'dragAndSlide', event:'click',      function: click,      target: '.js-drag-and-slide-link' })
  OBSERVER.on('dragAndSlide')
}


// Fonction permettant d'activer le calendrier
export const calendar = (root = document) => {
  if (!root.querySelector('#calendarOptions')) //s'il n'y a pas d'events
    return

  let eventsDatesList = root.querySelector('#calendarOptions').dataset.list
  let currentDate = root.querySelector('#calendarOptions').dataset.date

  $.fn.datepicker.dates['fr'] = {
    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    monthsShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Jui', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
    today: 'Aujourd\'hui',
    clear: 'Clear',
    format: 'dd/mm/yyyy',
    titleFormat: 'MM yyyy',
    weekStart: 0
  }

  let datesEnabled = eventsDatesList.split(',')
  
  $(root).find('.calendar').datepicker({
    language: 'fr',
    maxViewMode: 0,
    format: 'yyyy-mm-dd',
    todayHighlight: true,
    beforeShowDay: function (date) { // Rendre seulement les dates de la liste d'événements disponibles
      let allDates = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear()
      if(datesEnabled.indexOf(allDates) != -1) return true; else return false
    }
  }).on('changeDate', function(e) {
    let theTimestamp =  Date.parse(e.date)/1000 //Le timestamp du date picker est en millisecondes, il faut le mettre en secondes
    $.request('onChangeDate', {
      data: {dateFilter: theTimestamp},
      // update: {'evenementlist::eventslist':'#eventsWrapper'},
      // complete: function (data) { data.then(function(data){ eventsLoaded(data) })},
      complete: function (data) { data.then(function(data){ Barba.Pjax.goTo('/evenements/1/' + data['date'])})},
    })
    //Fermer l'overlay quand on clique
    $('.js-open-overlay-calendar').trigger('click')
  })

  $(root).find('.calendar').datepicker('update', currentDate)
}

export function proFilters() {

  $('.dropdown-option').on('click', function() {
    $('#form-example-select').trigger('change')
  })
}


export function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  navigator.clipboard.writeText(text).then(function() {
    console.log('Async: Copying to clipboard was successful!')
  }, function(err) {
    console.error('Async: Could not copy text: ', err)
  })
}
function fallbackCopyTextToClipboard(text) {
  var pos = $(document).scrollTop()

  var textArea = document.createElement('textarea')
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    document.execCommand('copy')
  } catch (err) { console.log(err)}

  document.body.removeChild(textArea)
  $(document).scrollTop(pos)
}

// Fonction gèrant le stickyBtn
export function stickyBtn() {
  var displayScroll = function() {
    isScrolledIntoView(document.getElementById('footer')) ? $('#wrapperStickyBtn').addClass('fadeOut') : $('#wrapperStickyBtn').removeClass('fadeOut')
    isScrolledIntoView(document.getElementById('footer')) ? $('#scrollUp').addClass('footerMobile') : $('#scrollUp').removeClass('footerMobile')
  }
  displayScroll()

  $(document).on('scroll', function() {
    displayScroll()
  })
}


function isScrolledIntoView(el) {
  var elemTop = el.getBoundingClientRect().top;
  var elemBottom = el.getBoundingClientRect().bottom;

  var isVisible = elemTop < window.innerHeight && elemBottom >= 0;
  return isVisible;
}
